import Vue from "vue";
import Component from "vue-class-component";

@Component
export class HasSyndicOptions extends Vue {
    syndicOptions: Array<{ offices: number[]; label: string; value: string }> = [];

    async getSyndicOptions() {
        const options = await import("@/assets/json/syndic.json");

        // @ts-ignore
        this.syndicOptions = options.default;
    }

    get officeSyndicOptions() {
        // @ts-ignore
        return this.selectedOffice ? this.syndicOptions.filter((option) => option.offices.includes(this.selectedOffice.id)) : this.syndicOptions;
    }
}
