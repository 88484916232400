
import { Action, Getter } from "vuex-class";
import { Component, Mixins, Ref } from "vue-property-decorator";
import { FormBase, FormMoney, FormPhone, FormInput, FormSelect, FormTextarea, FormSubmit, FormRadio, FormYesNo, FormYesOfficeOg } from "@/components/forms";
import { HasFormErrors } from "@/mixins/has-form-errors";
import { CreateMandatePayload, UpdateMandatePayload, Mandate, IndexMandatePayload, WorkStatus } from "@/store/modules/mandate.store";
import { ValidationProvider } from "vee-validate";
import { bus } from "@/main";
import { format } from "fecha";
import { HasSellers } from "@/mixins/has-sellers";
import { IndexOfficePayload, Office } from "@/store/modules/office.store";
import { activityIsDossier } from "@/utils/model-helpers";
import { IndexAccountPayload } from "@/store/modules/account.store";
import { HasNotaryOptions } from "@/mixins/has-notary-options";
import { HasSyndicOptions } from "@/mixins/has-syndic-options";

const scrollIntoView = require("scroll-into-view");
const cloneDeep = require("lodash/cloneDeep");

interface ConfigFieldSet {
    label?: string;
    placeholder?: string;
    type: "candidates" | "company-candidates" | "single-select" | "input" | "phone" | "number" | "surface" | "yes-no" | "yes-office-og" | "yes-office-og-nvt" | "multi-select" | "textarea" | "dropdown" | "price" | "vat-toggle" | "percent" | "date" | "label" | "comission" | "visit_moments" | "divider";
    class?: string;
    prefix?: string;
    key: string;
    callback?: string;
    required_with?: string;
    required_with_not?: string;
    required_with_when_available?: string;
    required_with_callback?: string;
    rules?: string;
    ghost_label?: boolean;
    show_label?: boolean;
    force_show?: boolean;
    max?: number;
    default?: string | number;
    options?: Array<{ label: string; value: string }> | string;
}

interface FormConfigSection {
    type: string;
    icon?: string;
    theme?: "primary" | "secondary";
    class?: string;
    fieldsets: ConfigFieldSet[];
}

interface FormConfigImport {
    include_part: string;
}

interface FormConfig {
    sections: Array<FormConfigSection | FormConfigImport>;
}

Component.registerHooks(["beforeRouteLeave"]);

@Component({
    components: {
        FormHeader: () => import("@/views/auth/mandate/parts/form-header.vue"),
        InputRadio: () => import("@/components/forms/property-radio.vue"),
        FormRepresentatives: () => import("@/components/forms/representatives.vue"),
        FormComission: () => import("@/components/forms/comission.vue"),
        FormCompanyRepresentatives: () => import("@/components/forms/company-representatives.vue"),
        FormVisitMoments: () => import("@/components/forms/visit-moments.vue"),
        Spinner: () => import("@/components/general/spinner.vue"),
        LayoutModal: () => import("@/layouts/components/modal.vue"),
        ClearMandateModal: () => import("@/components/modals/clear-mandate.vue"),
        FormInput,
        FormYesNo,
        FormYesOfficeOg,
        FormRadio,
        FormTextarea,
        FormSelect,
        FormMoney,
        FormBase,
        FormSubmit,
        FormPhone,
        ValidationProvider,
    },
})
export default class MandateCreate extends Mixins(HasFormErrors, HasSellers, HasNotaryOptions, HasSyndicOptions) {
    @Action("mandate/create") createMandate!: (payload: CreateMandatePayload) => Promise<Mandate>;
    @Action("mandate/patch") patchMandate!: (payload: UpdateMandatePayload) => Promise<Mandate>;
    @Action("mandate/index") indexMandate!: (payload: IndexMandatePayload) => Promise<Mandate[]>;
    @Action("activity/read") readActivity!: (payload: { id: number }) => Promise<Activity>;
    @Action("property/read") readProperty!: (payload: { activity_id: number }) => Promise<Property>;
    @Action("property/patchPrice") patchPropertyPrice!: (payload: any) => Promise<Property>;
    @Action("office/index") indexOffices!: (payload: IndexOfficePayload) => Promise<Office[]>;
    @Action("office/read") readOffice!: (officeId: number) => Promise<Office>;
    @Action("setOfficeId") setOfficeId!: (officeId: number) => void;
    @Action("account/indexForCurrentOffice") indexAccounts!: (payload: IndexAccountPayload) => Promise<Account[]>;

    @Getter("auth/me") me!: CurrentAccount;
    @Getter("property/viewing") property!: Property;
    @Getter("office/all") offices!: Office[];
    @Getter("currentOffice") officeId!: number;
    @Getter("account/all") accounts!: Account[];

    @Ref() form!: FormClass;

    WorkStatus = WorkStatus;

    type: WorkStatus | null = null;

    activity_id = Number(this.$route.params.id);

    mandate_id: number | null = null;

    payload: any = {};

    bus = bus;

    formData: FormConfig = { sections: [] };

    showForm = false;

    typeLoading = false;

    isDev = process.env.NODE_ENV !== "production";

    formLoading = false;

    showDraftChanges = false;

    saving = false;

    displayType: "sale" | "project" = "sale";

    myOffices: Array<{ label: string; value: string }> = [];

    properyAddress = {
        street: "",
        number: "",
        additional: "",
        zip: "",
        city: "",
        country: "",
    };

    selectedOffice?: Office | null = null;

    resolveCancelOrDiscard?: (payload: boolean) => Promise<boolean> = undefined;

    get isDossier() {
        return activityIsDossier(this.activity);
    }

    get name() {
        return `Bemiddelingsovereenkomst - ${this.activity.name}`;
    }

    get signatureOptions() {
        // @ts-ignore
        return [{ value: null, label: this.currentOffice.name }, ...this.accounts.filter((account: Account) => account.signature && account.id === this.me.id).map((account: Account) => ({ value: account.id, label: `${account.first_name} ${account.last_name}` }))];
    }

    get currentOffice() {
        return this.offices.find((office) => office.id === this.officeId);
    }

    async mounted() {
        this.formLoading = true;

        try {
            // If we have no activity, fetch it before all else
            if (!this.activity) {
                await this.readActivity({ id: this.activity_id });
            }

            if (!this.accounts.length) {
                this.indexAccounts({ q: { or: { roles: ["administrator", "user"] } }, per_page: 99 });
            }

            // If the activity is from a different office then the one we are viewing
            // Switch the viewing office and refresh the page.
            if (this.activity && this.activity.office_id !== this.officeId) {
                if (!this.me.offices!.includes(this.activity.office_id)) {
                    this.$router.push({ name: "error", params: { code: "401" } });
                } else {
                    this.setOfficeId(this.activity.office_id);

                    // @ts-ignore
                    this.$router.go(this.$router.currentRoute);
                }
            }

            // If we have no property info, fetch it before all else
            if (!this.property) {
                await this.readProperty({ activity_id: this.activity_id });
            }

            // Set the initial property address
            this.properyAddress = { ...this.property.address };

            // Lazy load options (notary/syndic)
            this.getNotaryOptions();
            this.getSyndicOptions();

            let promises = [];
            promises.push(await this.indexOffices({}));
            promises.push(await this.indexMandate({ s: { updated_at: "desc" }, q: { and: { activity_id: this.activity_id } }, per_page: 1 }));

            let [offices, mandates] = await Promise.all(promises);

            this.myOffices = cloneDeep(offices)
                .filter((o: Office) => this.me.offices!.includes(o.id))
                .map((o: Office) => ({ value: o.id, label: o.name }));

            if (mandates.length) {
                // @ts-ignore
                const mandate: Mandate = mandates[0];

                this.mandate_id = mandate.id;

                if (!mandate.details.type) {
                    throw new Error("No mandate type found");
                }

                this.type = mandate.details.type;

                this.payload = JSON.parse(JSON.stringify(mandate.details));

                this.selectedOffice = this.offices.find((o: Office) => o.id === this.payload.office_id) || null;

                await this.generateForm();

                // Backwards compatible and defaulting
                if (!this.payload.hasOwnProperty("article_3_price_estimate_option")) {
                    this.payload.article_3_price_estimate_option = "one_price";
                }

                if (this.payload.hasOwnProperty("article_5_period") && this.payload.article_5_period === null) {
                    this.payload.article_5_period = 6;
                }

                this.handleOnMountedCallbacks();
            }

            this.formLoading = false;
        } catch (e) {
            console.error(e);
            this.errorResponse = { status: 500, errors: [{ message: "something went wrong loading the mandate" }] };
        }
    }

    async generatePayload() {
        this.formData.sections.forEach((d) => {
            // @ts-ignore
            if (!d.hasOwnProperty("fieldsets") || !d.fieldsets) {
                return;
            }
            // @ts-ignore
            d.fieldsets.forEach((fs: any) => {
                if (!fs.key) {
                    return;
                }
                this.payload[fs.key] = null;
            });
        });
    }

    async generateForm() {
        let formData = (await import(`./config/${this.type}.json`)).default;

        for (let i = 0; i < formData.sections?.length ?? []; i++) {
            let section = formData.sections[i];
            if (section.hasOwnProperty("include_part")) {
                section = (await import(`./config/sections/${section.include_part}.json`)).default;
            }

            const fieldsets = section.fieldsets;

            section.fieldsets = [];
            for (let j = 0; j < fieldsets?.length ?? []; j++) {
                let fieldset = fieldsets[j];

                if (fieldset.hasOwnProperty("include_fieldset")) {
                    let importedFieldsets = (await import(`./config/fieldsets/${fieldset.include_fieldset}.json`)).default;
                    section.fieldsets = [...section.fieldsets, ...importedFieldsets];
                } else {
                    section.fieldsets.push(fieldset);
                }
            }

            this.formData.sections.push(section);
        }

        this.showForm = true;
    }

    async submit(redirect = true) {
        //@ts-ignore
        if (redirect && !this.form.observer.flags.dirty && this.form.observer.flags.valid && this.mandate_id) {
            this.$router.push({ name: "dossier-mandate-preview", params: { id: `${this.activity_id}`, mandateId: `${this.mandate_id}` } });
            return;
        }

        // Clean visit moments if they are of type array and not empty
        if (this.payload.visit_moments && Array.isArray(this.payload.visit_moments) && this.payload.visit_moments.length) {
            this.payload.visit_moments = this.payload.visit_moments.filter((vm: any) => vm.date && vm.duration);
        }

        try {
            if (!this.mandate_id) {
                const mandate = await this.createMandate({ activity_id: this.activity_id, details: { ...this.payload, type: this.type } });
                this.mandate_id = mandate.id;
            } else {
                await this.patchMandate({ id: this.mandate_id, activity_id: this.activity_id, details: { ...this.payload, type: this.type } });
            }

            if (this.payload.hasOwnProperty("article_3_publicity_price") && this.property.characteristics!.sale.offer_from !== this.payload.article_3_publicity_price && this.payload.article_3_publicity_price !== null) {
                this.property.characteristics.sale.offer_from = this.payload.article_3_publicity_price;

                this.patchPropertyPrice({ property_id: this.property.id, payload: { activity_id: this.activity_id, price: this.payload.article_3_publicity_price } });
            }

            if (redirect) {
                this.$router.push({ name: "dossier-mandate-preview", params: { id: `${this.activity_id}`, mandateId: `${this.mandate_id}` } });
            }
        } catch (e) {
            this.errorResponse = this.formatErrors(e);
        } finally {
            this.form.reset();
        }
    }

    handleInvalidSubmit() {
        this.scrollToFirstError();
    }

    scrollToFirstError() {
        const invalidElems = document.querySelectorAll(".invalid");

        if (invalidElems.length) {
            scrollIntoView(invalidElems[0]);
        }
    }

    async createNewForm() {
        this.typeLoading = true;

        await this.generateForm();

        await this.generatePayload();

        await this.getSellers();

        this.prefillData();

        this.typeLoading = false;

        this.$nextTick(() => {
            this.handleOnMountedCallbacks();
        });
    }

    prefillData() {
        if (this.payload.hasOwnProperty("candidates") && this.sellers.length) {
            this.payload.candidates = [];
            this.sellers.forEach((prefilled_seller) => {
                this.payload.candidates.push({
                    first_name: prefilled_seller.first_name ?? "",
                    last_name: prefilled_seller.last_name ?? "",
                    national_register_number: "",
                    birth_date: "",
                    birth_place: "",
                    marital_state: "",
                    phone: prefilled_seller.phone ?? "",
                    email: prefilled_seller.email ?? "",
                    address: {
                        street: "",
                        number: "",
                        additional: "",
                        zip: "",
                        city: "",
                    },
                });
            });
        }
        if (this.payload.hasOwnProperty("visit_moments")) {
            this.payload.visit_moments = [];
        }

        if (this.payload.hasOwnProperty("company_candidates") && this.sellers.length) {
            this.payload.company_candidates = this.generateInitialCompanyUser();
        }
        if (this.payload.hasOwnProperty("ground_owner_company_candidates") && this.sellers.length) {
            this.payload.ground_owner_company_candidates = this.generateInitialCompanyUser();
        }
        if (this.payload.hasOwnProperty("construction_owner_company_candidates") && this.sellers.length) {
            this.payload.construction_owner_company_candidates = this.generateInitialCompanyUser();
        }
        if (this.payload.hasOwnProperty("article_1_nature_of_the_good")) {
            this.payload.article_1_nature_of_the_good = this.property.characteristics.type ? this.$t(`property.types.${this.property.characteristics.type}`) : "";
        }
        if (this.payload.hasOwnProperty("article_1_location")) {
            this.payload.article_1_location = `${this.property.address.street} ${this.property.address.number}, ${this.property.address.zip} ${this.property.address.city}`;
        }
        if (this.payload.hasOwnProperty("article_1_cadastral_income")) {
            this.payload.article_1_cadastral_income = this.property.characteristics.type ? this.property.characteristics.cadastral_income : "";
        }
        if (this.payload.hasOwnProperty("signature_location")) {
            this.payload.signature_location = `${this.property.address.street} ${this.property.address.number}, ${this.property.address.zip} ${this.property.address.city}`;
        }
        if (this.payload.hasOwnProperty("article_1_surface")) {
            this.payload.article_1_surface = this.property.characteristics.ground_area ?? "";
        }
        if (this.payload.hasOwnProperty("article_1_surface")) {
            this.payload.article_1_surface = this.property.characteristics.ground_area ?? "";
        }
        if (this.payload.hasOwnProperty("article_3_publicity_price")) {
            this.payload.article_3_publicity_price = this.property.characteristics.sale.offer_from ?? "";
        }
        if (this.payload.hasOwnProperty("article_3_min_sales_price")) {
            this.payload.article_3_min_sales_price = this.property.characteristics.sale.minimum_offer ?? "";
        }
        if (this.payload.hasOwnProperty("article_3_price_estimate_option")) {
            this.payload.article_3_price_estimate_option = this.property.characteristics.sale.price_estimation !== null ? "one_price" : this.property.characteristics.sale.price_range_min !== null || this.property.characteristics.sale.price_range_max !== null ? "price_range" : null;
        }
        if (this.payload.hasOwnProperty("article_3_estimated_sales_price")) {
            this.payload.article_3_estimated_sales_price = this.property.characteristics.sale.price_estimation ?? "";
        }
        if (this.payload.hasOwnProperty("article_3_estimated_sales_price_min")) {
            this.payload.article_3_estimated_sales_price_min = this.property.characteristics.sale.price_range_min ?? "";
        }
        if (this.payload.hasOwnProperty("article_3_estimated_sales_price_max")) {
            this.payload.article_3_estimated_sales_price_max = this.property.characteristics.sale.price_range_max ?? "";
        }
        if (this.payload.hasOwnProperty("article_5_start_date")) {
            this.payload.article_5_start_date = format(new Date(), "YYYY-MM-DD");
        }
        if (this.payload.hasOwnProperty("article_5_period")) {
            this.payload.article_5_period = 6;
        }
        if (this.payload.hasOwnProperty("article_4_comission")) {
            if (this.activity.relative_commission !== null) {
                this.payload.article_4_comission = {
                    comission: this.activity.relative_commission,
                    type: "relative",
                    vat_included: this.activity.commission_vat_inclusive,
                };
            } else if (this.activity.fixed_commission !== null) {
                this.payload.article_4_comission = {
                    comission: this.activity.fixed_commission,
                    type: "fixed",
                    vat_included: this.activity.commission_vat_inclusive,
                };
            }
        }

        if (this.payload.hasOwnProperty("signature_entity")) {
            this.payload.signature_entity = null;
        }

        // Set a suggested office.
        if (!this.payload.office_id) {
            this.payload.office_id = this.activity.office_id;
            this.selectedOffice = this.offices.find((o: Office) => o.id === this.payload.office_id) || null;
        }
    }

    changeType() {
        if (!this.mandate_id) {
            return false;
        }
        bus.$emit("show-clear-mandate", {
            mandate_id: this.mandate_id,
            type: "reset",
            callback: () => {
                this.showForm = false;
                this.mandate_id = null;
                this.type = null;
                this.payload = {};
                this.formData = { sections: [] };
            },
        });
    }

    emptyForm() {
        if (!this.mandate_id) {
            return false;
        }
        const oldType = this.type;
        bus.$emit("show-clear-mandate", {
            mandate_id: this.mandate_id,
            type: "clear",
            callback: () => {
                this.showForm = false;
                this.mandate_id = null;
                this.payload = {};
                this.type = oldType;
                this.generateForm();
            },
        });
    }

    private generateInitialCompanyUser() {
        const prefilled_seller = this.sellers[0];
        return [
            {
                company: "",
                vat: "",
                first_name: prefilled_seller.first_name,
                last_name: prefilled_seller.last_name,
                national_register_number: "",
                birth_place: "",
                phone: prefilled_seller.phone,
                email: prefilled_seller.email,
                address: {
                    street: "",
                    number: "",
                    additional: "",
                    zip: "",
                    city: "",
                },
            },
        ];
    }

    async cancelOrDiscard() {
        this.showDraftChanges = true;
        return new Promise((resolve) => {
            //@ts-ignore
            this.resolveCancelOrDiscard = resolve;
        });
    }

    async beforeRouteLeave(to: any, from: any, next: any) {
        console.log("beforeRouteLeave");

        if (this.isDossier) {
            next();

            return;
        }

        //@ts-ignore
        if (this.form.observer.flags.dirty) {
            if (to.name === "dossier-mandate-preview") {
                //await this.submit(false);

                next();

                return;
            }

            const answer = await this.cancelOrDiscard();

            if (answer) {
                next();
            } else {
                try {
                    this.saving = true;

                    await this.submit(false);

                    this.formData = { sections: [] };

                    this.saving = false;

                    next();
                } catch (e) {
                    this.showDraftChanges = false;

                    this.saving = false;

                    next(false);
                }
            }
        }

        next();
    }

    possibleOfficeChange(key: string) {
        if (key !== "office_id" || !this.payload || !this.payload.office_id) {
            return;
        }

        if (this.selectedOffice && this.selectedOffice.id === this.payload.office_id) {
            return;
        }

        this.selectedOffice = this.offices.find((office: Office) => Number(office.id) === Number(this.payload.office_id));
    }

    handleYesNoCallback(answer: boolean, fieldset: ConfigFieldSet) {
        //@ts-ignore
        if (!fieldset || !fieldset.callback || !this[fieldset.callback]) {
            return;
        }

        this.$nextTick(() => {
            //@ts-ignore
            this[fieldset.callback](answer, fieldset);
        });
    }

    requiredWithValue(fieldset: ConfigFieldSet) {
        if (!fieldset.hasOwnProperty("required_with") && !fieldset.hasOwnProperty("required_with_not") && !fieldset.hasOwnProperty("required_with_callback") && !fieldset.hasOwnProperty("required_with_when_available")) {
            return true;
        }

        if (fieldset.required_with && (this.payload[fieldset.required_with] === true || this.payload[fieldset.required_with] === "provided_by_topo")) {
            return true;
        }

        if (fieldset.required_with_when_available && this.payload[fieldset.required_with_when_available] === "available") {
            return true;
        }

        if (fieldset.required_with_not && this.payload[fieldset.required_with_not] === false) {
            return true;
        }

        if (fieldset.hasOwnProperty("required_with_callback")) {
            if (fieldset.force_show) {
                return true;
            }
            return false; // Always handle with callback
        }

        return false;
    }

    private setIfExistsAndEmpty(key: string, value: any) {
        if (!this.payload.hasOwnProperty(key)) {
            return;
        }

        this.payload[key] = value;
    }

    private getFindOrFirstCandidate() {
        let candidate = {
            first_name: "",
            last_name: "",
            email: "",
            phone: "",
        };

        if (!this.payload) {
            return candidate;
        }

        if (this.payload.candidates && this.payload.candidates.length !== 0) {
            candidate.first_name = this.payload.candidates[0].first_name;
            candidate.last_name = this.payload.candidates[0].last_name;
            candidate.email = this.payload.candidates[0].email;
            candidate.phone = this.payload.candidates[0].phone;
        }

        if (this.payload.company_candidates && this.payload.company_candidates.length !== 0) {
            candidate.first_name = this.payload.company_candidates[0].first_name;
            candidate.last_name = this.payload.company_candidates[0].last_name;
            candidate.email = this.payload.company_candidates[0].email;
            candidate.phone = this.payload.company_candidates[0].phone;
        }

        return candidate;
    }

    private findOrFirstRenter(key: string) {
        let candidate = {
            first_name: "",
            last_name: "",
            email: "",
            phone: "",
        };

        if (key === "property_occupied" && this.payload.article_2_keys_available_holder_type === "renter") {
            candidate.first_name = this.payload.article_2_keys_available_holder_first_name;
            candidate.last_name = this.payload.article_2_keys_available_holder_last_name;
            candidate.email = this.payload.article_2_keys_available_holder_email;
            candidate.phone = this.payload.article_2_keys_available_holder_phone;
        }

        if (key === "keys_available" && this.payload.article_2_is_property_occupied_occupant_type === "renter") {
            candidate.first_name = this.payload.article_2_is_property_occupied_occupant_first_name;
            candidate.last_name = this.payload.article_2_is_property_occupied_occupant_last_name;
            candidate.email = this.payload.article_2_is_property_occupied_occupant_email;
            candidate.phone = this.payload.article_2_is_property_occupied_occupant_phone;
        }

        return candidate;
    }

    private handleOnMountedCallbacks() {
        this.__article_3_price_estimate_option(this.payload.article_3_price_estimate_option ?? "");
        this.__article_5_start_date_option(this.payload.article_5_start_date_option ?? "");
        this.__article_5_start_on_condition_option(this.payload.article_5_start_on_condition ?? "");
    }

    // CUSTOM CALLBACKS
    private __article_2_place_publicity_panel(answer: boolean, fieldset: ConfigFieldSet) {
        if (answer && this.payload.hasOwnProperty("article_2_place_publicity_panel_date") && this.payload.article_2_place_publicity_panel_date === null) {
            this.payload.article_2_place_publicity_panel_date = format(new Date(), "YYYY-MM-DD");
        }
    }

    private __article_2_is_property_occupied(answer: string, fieldset: ConfigFieldSet) {
        let contact = {
            first_name: "",
            last_name: "",
            email: "",
            phone: "",
        };

        if (answer === "owner") {
            contact = this.getFindOrFirstCandidate();
        } else if (answer === "renter") {
            contact = this.findOrFirstRenter("property_occupied");
        }

        this.setIfExistsAndEmpty("article_2_is_property_occupied_occupant_first_name", contact.first_name);
        this.setIfExistsAndEmpty("article_2_is_property_occupied_occupant_last_name", contact.last_name);
        this.setIfExistsAndEmpty("article_2_is_property_occupied_occupant_email", contact.email);
        this.setIfExistsAndEmpty("article_2_is_property_occupied_occupant_phone", contact.phone);
    }

    private __article_2_keys_available(answer: string, fieldset: ConfigFieldSet) {
        let contact = {
            first_name: "",
            last_name: "",
            email: "",
            phone: "",
        };

        if (answer === "owner") {
            contact = this.getFindOrFirstCandidate();
        } else if (answer === "renter") {
            contact = this.findOrFirstRenter("keys_available");
        }

        this.setIfExistsAndEmpty("article_2_keys_available_holder_first_name", contact.first_name);
        this.setIfExistsAndEmpty("article_2_keys_available_holder_last_name", contact.last_name);
        this.setIfExistsAndEmpty("article_2_keys_available_holder_email", contact.email);
        this.setIfExistsAndEmpty("article_2_keys_available_holder_phone", contact.phone);
    }

    private __sales_information_fuel_or_oil_tank_present(answer: string, fieldset: ConfigFieldSet){

        if(!answer){
            this.setIfExistsAndEmpty("sales_information_fuel_or_oil_tank_amount_underground", null);
            this.setIfExistsAndEmpty("sales_information_fuel_or_oil_tank_amount_above_ground", null);
        }
    }

    private __article_3_price_estimate_option(answer: string) {
        const s_index = this.formData.sections.findIndex((s) => {
            return (
                s.hasOwnProperty("fieldsets") &&
                //@ts-ignore
                s.fieldsets.find((f) => {
                    return f.key === "article_3_price_estimate_option";
                })
            );
        });

        if (s_index === -1) {
            return;
        }

        //@ts-ignore
        const sf_1 = this.formData.sections[s_index]!.fieldsets!.findIndex((f) => f.key === "article_3_estimated_sales_price");
        //@ts-ignore
        const sf_2 = this.formData.sections[s_index]!.fieldsets!.findIndex((f) => f.key === "article_3_estimated_sales_price_min");
        //@ts-ignore
        const sf_3 = this.formData.sections[s_index]!.fieldsets!.findIndex((f) => f.key === "article_3_estimated_sales_price_max");

        if (sf_1 === -1 || sf_2 === -1 || sf_3 === -1) {
            return;
        }

        if (answer === "one_price") {
            //@ts-ignore
            this.formData.sections[s_index].fieldsets[sf_1].force_show = true;
            //@ts-ignore
            this.formData.sections[s_index].fieldsets[sf_2].force_show = false;
            //@ts-ignore
            this.formData.sections[s_index].fieldsets[sf_3].force_show = false;
        }

        if (answer === "price_range") {
            //@ts-ignore
            this.formData.sections[s_index].fieldsets[sf_1].force_show = false;
            //@ts-ignore
            this.formData.sections[s_index].fieldsets[sf_2].force_show = true;
            //@ts-ignore
            this.formData.sections[s_index].fieldsets[sf_3].force_show = true;
        }
    }

    private __article_5_start_date_option(answer: string) {
        const s_index = this.formData.sections.findIndex((s) => {
            return (
                s.hasOwnProperty("fieldsets") &&
                //@ts-ignore
                s.fieldsets.find((f) => {
                    return f.key === "article_5_start_date_option";
                })
            );
        });

        if (s_index === -1) {
            return;
        }

        //@ts-ignore
        const sf_1 = this.formData.sections[s_index]!.fieldsets!.findIndex((f) => f.key === "article_5_start_date");
        //@ts-ignore
        const sf_2 = this.formData.sections[s_index]!.fieldsets!.findIndex((f) => f.key === "article_5_start_on_condition");
        //@ts-ignore
        const sf_3 = this.formData.sections[s_index]!.fieldsets!.findIndex((f) => f.key === "article_5_start_date_other_conditions");

        if (sf_1 === -1 || sf_2 === -1 || sf_3 === -1) {
            return;
        }

        if (answer === "start_on_date") {
            //@ts-ignore
            this.formData.sections[s_index].fieldsets[sf_1].force_show = true;
            //@ts-ignore
            this.formData.sections[s_index].fieldsets[sf_2].force_show = false;
            //@ts-ignore
            this.formData.sections[s_index].fieldsets[sf_3].force_show = false;
        }
        if (answer === "start_on_conditions") {
            //@ts-ignore
            this.formData.sections[s_index].fieldsets[sf_1].force_show = false;
            //@ts-ignore
            this.formData.sections[s_index].fieldsets[sf_2].force_show = true;
            //@ts-ignore
            this.formData.sections[s_index].fieldsets[sf_3].force_show = false;
        }
    }

    private __article_5_start_on_condition_option(answer: string) {
        const s_index = this.formData.sections.findIndex((s) => {
            return (
                s.hasOwnProperty("fieldsets") &&
                //@ts-ignore
                s.fieldsets.find((f) => {
                    return f.key === "article_5_start_on_condition";
                })
            );
        });

        if (s_index === -1) {
            return;
        }

        //@ts-ignore
        const sf_1 = this.formData.sections[s_index]!.fieldsets!.findIndex((f) => f.key === "article_5_start_date");
        //@ts-ignore
        const sf_2 = this.formData.sections[s_index]!.fieldsets!.findIndex((f) => f.key === "article_5_start_on_condition");
        //@ts-ignore
        const sf_3 = this.formData.sections[s_index]!.fieldsets!.findIndex((f) => f.key === "article_5_start_date_other_conditions");

        if (sf_1 === -1 || sf_2 === -1 || sf_3 === -1) {
            return;
        }

        if (answer === "from_other") {
            //@ts-ignore
            this.formData.sections[s_index].fieldsets[sf_1].force_show = false;
            //@ts-ignore
            this.formData.sections[s_index].fieldsets[sf_3].force_show = true;
        } else {
            //@ts-ignore
            this.formData.sections[s_index].fieldsets[sf_3].force_show = false;
        }
    }
}
